import "./App.css";
import "./chron-builder.css";

import React, { useEffect, useState, lazy, Suspense } from "react";
import { useHistory } from "react-router-dom";

import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
// import Box from "@material-ui/core/Box";
// import Button from "@material-ui/core/Button";

// import PopUp from "./PopUp";

// import Checkbox from "@material-ui/core/Checkbox";
// import FormControlLabel from "@material-ui/core/FormControlLabel";

import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";

// import Badge from "@material-ui/core/Badge";
// import Container from "@material-ui/core/Container";
// import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Link from "@material-ui/core/Link";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Close from "@mui/icons-material/Close";

// import NotificationsIcon from "@mui/icons-material/Notifications";
import { mainListItems, secondaryListItems } from "./listItems";
import { Route, Switch, useLocation } from "react-router-dom";
// import Login from "./btn_google_signin_light_normal_web.png";
import ZoomcorderLogo from "./Zoomcorder_logo.png";
import ZoomcorderLogo2 from "./Zoomcorder_logo2.png";



import GoogleLogin from "react-google-login";
import { GoogleLogout } from "react-google-login";

import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
// import { SketchPicker } from "react-color";
import firebase from "firebase/app";
import "firebase/database";
import "firebase/storage";
import "firebase/auth";
// import gapi from "gapi";

// import { google } from "googleapis";

import Popover from "@material-ui/core/Popover";
//const {Storage} = require('@google-cloud/storage');
// import { createBrowserHistory } from "history";
// var pageHistory = createBrowserHistory();
import Fallback from "./Fallback.js";
import { sendErrorReport } from "./util/sendMessage";

const load = (Component: any) => (props: any) =>
  (
    <Suspense fallback={<Fallback />}>
      <Component {...props} />
    </Suspense>
  );

const Recordings = load(lazy(() => import("./Recordings.js")));
const Dash = load(lazy(() => import("./Dash.js")));
const Schedule = load(lazy(() => import("./Schedule.js")));
const Hist = load(lazy(() => import("./Hist.js")));
const Settings = load(lazy(() => import("./Settings.js")));
const Support = load(lazy(() => import("./Support.js")));
const TOS = load(lazy(() => import("./TOS.js")));
const privacy = load(lazy(() => import("./Privacy.js")));
const ExtPrivacy = load(lazy(() => import("./extPrivacy.js")));
const DMCA = load(lazy(() => import("./DMCA.js")));
const Pricing = load(lazy(() => import("./Pricing.js")));
const Pricing2 = load(lazy(() => import("./Pricing2.js")));
const Success = load(lazy(() => import("./success.js")));

const DataTool = load(lazy(() => import("./dataTool.js")));

var histListen;

var useruid = "";
var authD = "zoocorder.firebaseapp.com";
// var apiK = "AIzaSyBkMIvidIGAsmwbkX16_m45aNdTWdfe7t8";
console.log("Running:");
if (process.env.NODE_ENV === "development") {
  //apiK = "AIzaSyD8VGzDCn8yWzVRRDnWFoVVR-pyTLD_StM";
  //apiK = "AIzaSyCLsHQoNO78I1fmqjEcVvQCG1Rna11QATE";
  authD = "http://localhost:3000";
  //authD = "localhost:3000";
  //authD = "localhost";
}

var GoogleAuth; // Google Auth object.

var provider = new firebase.auth.GoogleAuthProvider();
provider.addScope("https://www.googleapis.com/auth/drive.metadata.readonly");
provider.addScope("https://www.googleapis.com/auth/drive.file");
provider.addScope("https://www.googleapis.com/auth/userinfo.email");
for (var key in localStorage) {
  if (key.includes("firebaseio.com")) {
    localStorage.removeItem(key);
  }
}

var firebaseConfig = {
  apiKey: "AIzaSyBkMIvidIGAsmwbkX16_m45aNdTWdfe7t8",
  authDomain: authD,
  databaseURL: "https://zoocorder.firebaseio.com",
  projectId: "zoocorder",
  storageBucket: "zoocorder.appspot.com",
  messagingSenderId: "282767790318",
  appId: "1:282767790318:web:c955404a751656a59a63ff",
  measurementId: "G-C0DF9JP1QV",
};

firebase.initializeApp(firebaseConfig);

var storage = firebase.storage();

var gettingStripe;
var gotHistory = true;
var gotStuff = true;
var gotUser = false;
var schedState;
var histState;
var userState;
var clickedSchedule = false;
var apiStatus = 0;
var geoLocation = "";
var newlyRegistered = false;
var stripeTestSucc = "";
var tempSessionId

function clickedScheduleEvent(value) {
  clickedSchedule = value;
}
function sortObjBy(obje, sortval) {
  var arr = Object.values(obje);
  //console.log("arri", arr);
  arr = arr.filter(function (obj) {
    //console.log("valisi", Object.values(obj)[0][sortval]);
    return Object.values(obj)[0][sortval] > Date.now();
  });
  //console.log("arri2", arr);
  var returnObj = toObject(arr, "jobid");
  //var returnObj = Object.assign({}, arr);
  //console.log("returnObj", returnObj);
  return returnObj;
}
function sortObjBySingle(obje, sortval) {
  //console.log("sortbyobj");
  if (obje) {
    var arr = Object.values(obje);
    //console.log("arri", arr);
    arr = arr.filter(function (obj) {
      return (
        Object.values(obj)[0][sortval] > Date.now() &&
        !Object.values(obj)[0]["single"]
      );
    });
    //console.log("arri2", arr);
    var returnObj = toObject(arr, "jobid");
    //var returnObj = Object.assign({}, arr);
    //console.log("returnObj", returnObj);
    return returnObj;
  }
}
function monthDiff(ts1, ts2) {
  var d1 = new Date(ts1);
  var d2 = new Date(ts2);

  var months;
  months = (d2.getFullYear() - d1.getFullYear()) * 12;
  months -= d1.getMonth();
  months += d2.getMonth();
  return months <= 0 ? 0 : months;
}

function toObject(arr, keyval) {
  var rv = {};
  for (var i = 0; i < arr.length; ++i)
    rv[Object.values(arr[i])[0][keyval]] = arr[i];
  return rv;
}
firebase
  .auth()
  .signOut()
  .then(function () {
    gotHistory = false;
    console.log("firebase logout success");
  })
  .catch(function (error) {
    console.log("firebase logout error", error);
  });
export default function Dashboard(props) {
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  var rawQuery = useLocation().search;
  const query = useQuery();
  var sessionId = localStorage.getItem("sessionId");
  if (query.get("session_id") && query.get("session_id") !== "null") {
    if(!tempSessionId){
      console.log("herekeith2")
    sendErrorReport(
      { message: "sessionID Returned:" },
      query.get("session_id"),
      props.user
    );
  }
    sessionId = query.get("session_id");
    localStorage.setItem("sessionId", query.get("session_id"));
    tempSessionId = sessionId

  }

  var meetingParam = query.get("meeting");
  var passCodeParam = query.get("pass");
  var dateParam = query.get("date");
  var timeZoneParam = query.get("tz");
  var titleParam = query.get("title");
  var firstNameParam = query.get("firstname");
  var lastNameParam = query.get("lastname");
  var stripeTestInProgress
  const ReactGA = props.reactGA;

  var page = Math.random() < 0.5;
  var userDataBody;

  userDataBody = {
    ...userDataBody,
    ...{
      tier: "new",
      purchaseDate: Date.now(),
    },
  };

  useEffect(() => {
    function areScrollbarsVisible() {
      var scrollableElem = document.createElement("div"),
        innerElem = document.createElement("div");
      scrollableElem.style.width = "30px";
      scrollableElem.style.height = "30px";
      scrollableElem.style.overflow = "scroll";
      scrollableElem.style.borderWidth = "0";
      innerElem.style.width = "30px";
      innerElem.style.height = "60px";
      scrollableElem.appendChild(innerElem);
      document.body.appendChild(scrollableElem); // Elements only have width if they're in the layout
      var diff = scrollableElem.offsetWidth - scrollableElem.clientWidth;
      document.body.removeChild(scrollableElem);
      return diff > 0;
    }

    window.addEventListener("load", function () {
      // Show scrollbars if they're hidden.
      if (!areScrollbarsVisible()) {
        document.body.classList.add("force-show-scrollbars");
      }
    });

    if (
      window.location.pathname.split("/")[1] === "j" ||
      window.location.pathname.split("/")[1] === "webinar" ||
      window.location.pathname.split("/")[1] === "meeting"
    ) {
      History.push({
        pathname: "/schedule/recordzoom",
        search: `meeting=https://zoom.us${window.location.href
          .split("&")
          .join("%26")
          .split(window.location.hostname)[1]
          .split("/j/")
          .join("/webinar/register/")}`,
      });
    }

    // window.$crisp = [];
    // window.CRISP_WEBSITE_ID = "35252481-3485-447c-8bed-6fe1283013cc";
    // (function () {
    //   d = document;
    //   s = d.createElement("script");
    //   s.src = "https://client.crisp.chat/l.js";
    //   s.async = 1;
    //   d.getElementsByTagName("head")[0].appendChild(s);
    // })();
    //console.log("geostart");
    let headers = {
      "Content-Type": "text/plain",
    };

    if (
      navigator.language === "en-US" ||
      navigator.browserLanguage === "en-US" ||
      navigator.systemLanguage === "en-US" ||
      navigator.userLanguage === "en-US"
    ) {
      userDataBody.trial = "UsUserTrial";
      //console.log("trialtrigger");
    }
  }, []);

  //const storage = new Storage();
  const [location, setLocation] = useState(window.location.pathname);

  const [background, setBackground] = useState("#3f51b5");
  const [textColor, setText] = useState("#FFF");

  const [token, handleTokenChange] = useState("");
  // var token;
  // var handleTokenChange = (input) => {
  //   token = input;
  // };
  const [accessToken, handleAccessTokenChange] = useState("");
  // var accessToken;
  // var handleAccessTokenChange = (input) => {
  //   accessToken = input;
  // };

  const [userID, handleUserIDChange] = useState("");
  // var userID;
  // var handleUserIDChange = (input) => {
  //   userID = input;
  // };
  const [email, handleEmailChange] = useState("");
  // var email;
  // var handleEmailChange = (input) => {
  //   email = input;
  // };
  const [history, handleHistoryChange] = useState();
  const [finalHistory, handleFinalHistoryChange] = useState();
  const [schedule, handleScheduleChange] = useState("");
  // var history;
  // var finalHistory;
  // var schedule;
  // var handleHistoryChange = (input) => {
  //   console.log("new hist = ", input);
  //   history = input;
  // };
  // var handleFinalHistoryChange = (input) => {
  //   finalHistory = input;
  // };
  // var handleScheduleChange = (input) => {
  //   schedule = input;
  // };

  const [user, handleUserChange] = useState({
    defaults: {},
    defaultSettings: {},
  });
  // var user = {
  //   defaults: {},
  //   defaultSettings: {},
  // };
  // var handleUserChange = (input) => {
  //   user = input;
  // };

  const [loggedIn, handleLoggedInChange] = useState(true);

  const [uID, handleUIDChange] = useState();
  // var uID;
  // var handleUIDChange = (input) => {
  //   uID = input;
  // };
  const [refreshToken, handleRefreshTokenChange] = useState();
  // var refreshToken;
  // var handleRefreshTokenChange = (input) => {
  //   refreshToken = input;
  // };
  var IdTokenHolder;

  const [IdToken, handleIdTokenChange] = useState();
  // var IdToken;
  // var handleIdTokenChange = (input) => {
  //   IdToken = input;
  // };

  const [profile, handleProfileChange] = useState();
  // var profile;
  // var handleProfileChange = (input) => {
  //   profile = input;
  // };
  const [logoutPopup, handleLogoutPopup] = useState();

  const [anchorEl, setAnchorEl] = useState(null);

  const [open, setOpen] = useState(
    localStorage.getItem("drawerOpen") == "true"
  );
  const [deleteQueue, setDeleteQueue] = useState({});
  const clientID = process.env.REACT_APP_CLIENT_ID;
  const clientSecret = process.env.REACT_APP_CLIENT_SECRET;

  // var open = localStorage.getItem("drawerOpen") == "true";

  // var setOpen = (input) => {
  //   open = input;
  // };
  var d = document;
  var s = d.createElement("script");
  useEffect(() => {
    if (!sessionId) {
      sessionId = query.get("session_id");
    }

    if (
      user &&
      sessionId &&
      (IdTokenHolder || IdToken) &&
      sessionId !== user.sessionId
    ) {
      if (IdTokenHolder) {
        userDataBody.token = IdTokenHolder;
      } else {
        userDataBody.token = IdToken;
      }
      var headers = {
        "Content-Type": "text/plain",
      };
      fetch(
        "https://us-central1-zoocorder.cloudfunctions.net/" + "saveUserData",
        {
          method: "POST",
          body: JSON.stringify({
            sessionId: sessionId,
            token: userDataBody.token,
          }),
          headers: headers,
        }
      )
        .then((res) => {
          console.log("sessionId saved");
        })
        .catch((err) => {
          console.log("Session ID save failed", JSON.stringify(err));
          alert(
            "sessionID failed to save please contact support if you experience problems"
          );
        });
    }
    if (user) {
      if (
        (sessionId && sessionId !== "null") ||
        (user.sessionId && user.sessionId !== "null")
      ) {
        if (stripeTestSucc !== sessionId && stripeTestSucc !== user.sessionId) {
          stripeTest();
        }
      }
    }
  }, [sessionId, user, userDataBody, IdTokenHolder, IdToken]);

  let History = useHistory();


  function setScheduleHistUser() {
    console.log(
      "setScheduleChange",
      schedState,
      histState,
      schedState && histState
    );
    histSetter();
    // if (apiStatus === 0) {
    //   console.log("apiStatus0 hist");

    //   histSetter();

    //   apiStatus = 1;
    // } else if (apiStatus === 1) {
    //   console.log("apiStatus1");
    //   apiStatus = 2;
    //   histSetter();

    //   setTimeout(function () {
    //     histSetter();

    //     apiStatus = 1;
    //   }, 5000);
    // }
  }

  function histSetter() {
    if (schedule !== schedState && schedState) {
      handleScheduleChange(schedState);
    }

    if (history !== histState && histState) {
      handleHistoryChange(histState);
    }
  }

  var itt = 0;

  var headers = {
    "Content-Type": "text/plain",
  };
  function waitForElementStripe(data, snap, sessionId) {
    console.log("data", data, snap, user)
    if (snap.tier !== userDataBody.tier || snap.tier !== user?.tier) {
      if ((data || IdTokenHolder || IdToken) && snap) {
        console.log("savetier", userDataBody, snap);
        userDataBody = { ...userDataBody, ...snap };
        userDataBody.token = data || IdTokenHolder || IdToken;
        if (!snap || !snap.tier) {
          userDataBody.tier = "new";

        } 
        if (
          (navigator.language === "en-US" ||
            navigator.browserLanguage === "en-US" ||
            navigator.systemLanguage === "en-US" ||
            navigator.userLanguage === "en-US") &&
          (!snap || !snap.tier || snap.tier === "new")
        ) {
          userDataBody.trial = "UsUserTrial";
          //console.log("trialtrigger");
        }
        if (snap.purchaseDate) {
          userDataBody.renewalTerm =
            userDataBody.renewalTerm + (snap.renewalTerm - snap.purchaseDate);
        }
        fetch(
          "https://us-central1-zoocorder.cloudfunctions.net/" + "saveUserData",
          {
            method: "POST",
            body: JSON.stringify(userDataBody),
            headers: headers,
          }
        )
          .then((res) => {
            console.log("herekeith4")

            sendErrorReport(
              { message: "Subscription Saved:" },
              JSON.stringify(userDataBody),
              props.user
            );
            stripeTestSucc = sessionId;
            localStorage.setItem("checkSub", false);
            gotHistory = false;
            if (localStorage.getItem("meetingParam")) {
              let meetingParamHolder = localStorage.getItem("meetingParam");
              localStorage.setItem("meetingParam", false);
              History.push({
                pathname: "/schedule",
                search: `meeting=${meetingParamHolder}&auto=true`,
              });
            } else {
              History.push("/schedule");
            }
          })

          .catch((err) => {
            console.log("saveuserdataerrorlog", JSON.stringify(err));
          });
      } else {
        itt++;
        console.log("no IdToken", data);
      }
    }
  }
  function stripeTest() {
    if(!stripeTestInProgress){
      stripeTestInProgress = true;
    let stripeSessionId;
    if (sessionId) {
      stripeSessionId = sessionId;
      //setsessionId
    } else if (user && user.sessionId) {
      stripeSessionId = user.sessionId;
    }
    if (stripeSessionId) {
      var data = JSON.stringify({
        sessionId: stripeSessionId,
      });
      var headers = {
        "Content-Type": "text/plain",
      };
      fetch("https://us-central1-zoocorder.cloudfunctions.net/CheckStripeSub", {
        method: "POST",
        body: data,
        headers: headers,
      })
        .then((res) => {
          return res.json();
        })
        .then((res) => {
          var desc = res.lines.data[0].description;
          console.log("data", desc);
          userDataBody.purchaseDate = Date.now();
          userDataBody.cancelled = false;

          if (desc) {
            userDataBody.renewalTerm = Date.now() + 2592000000 * 12;
            userDataBody.receipt = res;
            userDataBody.stripe = true;
            if (desc.includes("Annual")) {
              userDataBody.tier = "annualPro";
              userDataBody.renewalTerm = Date.now() + 2592000000 * 12;
            }
            if (desc.includes("Weekly")) {
              userDataBody.tier = "weeklyPro";
              userDataBody.renewalTerm = Date.now() + 604800000;
            }
            if (desc.includes("Corp")) {
              userDataBody.tier = "annualCorpPro";
              userDataBody.renewalTerm = Date.now() + 2592000000 * 12;
            }
          }

          waitForElementStripe(IdToken, userDataBody, stripeSessionId);
          stripeTestInProgress = false;
        })
        .catch((err) => {
          stripeTestInProgress = false;
          console.log(err);
          // alert("stripe key error", err);
        });
    }
  }
  }
  //console.log("hist first render");
  firebase.auth().onAuthStateChanged(function (firebaseUser) {
    // if (user) {
    //   console.log("firebase User", user);

    //   handleProfileChange({
    //     imageUrl: user.photoURL,
    //     givenName: user.displayName.split(" ")[0],
    //     familyName: user.displayName.split(" ")[1],
    //     email: user.email,
    //   });
    //   handleUserIDChange(user.uid);
    //   handleEmailChange(user.email);
    // }
    //console.log("hist auth complete");

    if (!gotUser && firebaseUser) {
      gotUser = true;
      //console.log("hist auth complete2");

      firebaseUser.getIdToken().then(function (data) {
        console.log("hist auth complete fid");

        //console.log("settokenholder", data);
        IdTokenHolder = data;
        handleIdTokenChange(data);
        if (!uID && user) {
          handleUIDChange(user.uid);
          useruid = user.uid;

          //console.log("auth token:", data);

          if (!gotHistory && firebaseUser.uid) {
            //console.log("gothistory", gotHistory, firebaseUser.uid);
            gotHistory = true;
            firebase
              .database()
              .ref(`/users/${firebaseUser.uid}/`)
              .on("value", function (snapshot) {
                console.log("firebaseUser set hist", snapshot.val());
                if (snapshot.val()) {
                  userState = {
                    ...{ defaults: {}, defaultSettings: {} },
                    ...snapshot.val().data,
                  };
                } else {
                  userState = {
                    ...{ defaults: {}, defaultSettings: {} },
                  };
                }
                if (userState !== user && userState) {
                  handleUserChange(userState);
                }
                if (true) {
                  if (
                    (snapshot.val() &&
                      snapshot.val().data &&
                      !snapshot.val().data.tier) ||
                    (snapshot.val() &&
                      snapshot.val().data.tier === "new" &&
                      userDataBody.trial === "UsUserTrial" &&
                      !snapshot.val().data.trial)
                  ) {
                    //console.log("setusertier");
                    newlyRegistered = true;
                    waitForElementStripe(data, snapshot.val().data);
                    if (
                      (window.location.pathname === "/" ||
                        window.location.pathname === "") &&
                      clickedSchedule
                    ) {
                      // History.push("/pricing");
                    }
                  } else {
                    if (
                      snapshot.val() &&
                      snapshot.val().data.tier &&
                      !newlyRegistered
                    ) {
                      if (window.location.href.includes("/record")) {
                        let tempHref;
                        if (window.location.href.includes("?")) {
                          tempHref = `${window.location.href.replace(
                            "?",
                            "/existingUser?"
                          )}`;
                        } else {
                          tempHref = `${window.location.href}/existingUser`;
                        }
                      }
                      if (
                        window.location.href.includes(
                          "/SubscriptionSuccess?" 
                        ) && !gettingStripe
                      ) {
                        gettingStripe = true;

                        waitForElementStripe(data, snapshot.val().data);
                      }
                    }
                    // console.log("nav to schedule");
                    // History.push("/schedule");
                  }
                }
              });
          }
          var captureRef = firebase
            .database()
            .ref(`/capture/${firebaseUser.uid}/`);
          captureRef.on("value", function (snapshot) {
            console.log("history set hist", snapshot.val());
            histState = snapshot.val();
            setScheduleHistUser();
          });
          var accountLogRef = firebase
            .database()
            .ref(`/accountLog/${firebaseUser.uid}`);

          accountLogRef.on("value", function (snapshot) {
            var acl = snapshot.val();

            var sched = sortObjBySingle(acl, "lastDate");
            console.log("schedule set hist", sched);
            schedState = sched;
            setScheduleHistUser();
          });
        }
      });
    }
  });

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const openProfile = Boolean(anchorEl);
  const id = openProfile ? "simple-popover" : undefined;

  const theme = createTheme({
    palette: {
      primary: {
        main: "#1b72f0",
      },
    },
    button: {
      "text-transform": "none",
    },
  });
  function logoutButton() {
    if (logoutPopup) {
      handleLogoutPopup(false);
    } else {
      handleLogoutPopup(true);
    }
  }

  function signOutUser() {
    handleLogoutPopup(false);
    var x = document.getElementById("logoutbutton");
    if (x) {
      var logoutButton = document
        .getElementById("logoutbutton")
        .getElementsByTagName("button")[0];
      if (logoutButton) {
        logoutButton.click();
      }
    } else {
      itt++;
      if (itt < 15) {
        setTimeout(signOutUser, 500);
      }
    }
  }
  const responseGoogle = (response) => {
    console.log(response);
  };

  const successresponseGoogle = (response) => {
    ReactGA.event({
      id: Math.floor(Math.random() * 899999999 + 100000000),
      category: "login Success!",
      action: "Login complete",
    });
    gotHistory = false;

    var apiServer = "";
    var tokenHolder = response.tokenObj;

    apiServer = "https://us-central1-zoocorder.cloudfunctions.net/";

    if (response.code) {
      var headers = {
        "Content-Type": "text/plain",
      };
      var codeBod = {
        client_id: clientID,
        client_secret: clientSecret,
        redirect_uri: "postmessage",
        grant_type: "authorization_code",
        code: response.code,
      };

      console.log("grefreshbod", codeBod);
      fetch(apiServer + "gRefresh", {
        method: "POST",
        body: JSON.stringify(codeBod),
        headers: headers,
      })
        .then((res) => {
          return res.json();
        })
        .then((preResponse) => {
          console.log("grefresh response", preResponse);
          if (!preResponse.refresh_token) {
            alert(
              `Google Sign in did not send refresh_token! Contact zoomcorderhelp@gmail.com with this message:
              ${JSON.stringify(preResponse)}, 
              -
              ${JSON.stringify(codeBod)}`
            );
          }

          var headers = {
            "Content-Type": "text/plain",
          };
          handleTokenChange(preResponse.id_token);

          var response = JSON.parse(atob(preResponse.id_token.split(".")[1]));

          console.log(
            "open-id response",
            atob(preResponse.id_token.split(".")[0]),
            response,
            preResponse.id_token.split(".")[2]
          );

          var googleUser = response;
          googleUser.imageUrl = googleUser.picture;
          handleProfileChange(googleUser);
          handleUserIDChange(googleUser.sub);
          handleEmailChange(googleUser.email);

          console.log("tokenholdr", preResponse);
          console.log("access Token:", preResponse.access_token);

          var credential = provider.credential(preResponse.id_token);
          // const oAuth2Client = new google.auth.OAuth2(
          //   client_id,
          //   client_secret,
          //   redirect_uris[0]
          // );
          // oAuth2Client.setCredentials(JSON.parse(token));

          // oAuth2Client.getToken(code, (err, token) => {
          //   if (err) {
          //     console.error("Error retrieving access token", err);
          //   }

          //   oAuth2Client.setCredentials(token);
          //   // Store the token to disk for later program executions
          //   fs.writeFile(TOKEN_PATH, JSON.stringify(token), (err) => {
          //     if (err) {
          //       console.error(err);
          //     }
          //     console.log("Token stored to", TOKEN_PATH);
          //   });
          // });

          firebase
            .auth()
            .signInWithCredential(credential)
            .then((credential) => {
              console.log("signInWith credential", credential, credential.user);

              if (credential.user) {
                handleRefreshTokenChange(credential.user.refreshToken);
              }
              userDataBody = {
                email: googleUser.email,
                scope: preResponse.scope,
                token_type: preResponse.token_type,
                expiry_date: preResponse.expires_at,
                user: googleUser,
                access_token: preResponse.access_token,
                refresh_Token: preResponse,
              };

              handleAccessTokenChange({
                access_token: preResponse.access_token,
                scope: preResponse.scope,
                token_type: preResponse.token_type,
                expiry_date: preResponse.expires_at,
              });
              var headers = {
                "Content-Type": "text/plain",
              };
              var itt = 0;
              function waitForElement() {
                if (userDataBody.refresh_Token) {
                  if (IdTokenHolder || IdToken) {
                    if (IdTokenHolder) {
                      userDataBody.token = IdTokenHolder;
                    } else {
                      userDataBody.token = IdToken;
                    }
                    console.log("saveuserdatabody", userDataBody);
                    fetch(apiServer + "saveUserData", {
                      method: "POST",
                      body: JSON.stringify(userDataBody),
                      headers: headers,
                    })
                      .then((res) => {
                        gotHistory = false;

                        console.log("SaveUserData", res, res.body);
                      })

                      .catch((err) => {
                        console.log(err);
                        alert(
                          `schedule errored response:",
                      ${JSON.stringify(err)},
                      body:,
                      ${JSON.stringify(userDataBody)}`
                        );
                      });
                  } else {
                    itt++;
                    console.log(
                      "no IdToken",
                      IdTokenHolder,
                      IdToken,
                      userDataBody,

                      userDataBody.refresh_Token.refresh_token
                    );
                    if (itt < 150) {
                      setTimeout(waitForElement, 500);
                    } else {
                      alert(
                        `Google Sign in timed out! Contact zoomcorderhelp@gmail.com with this message:
                        ${JSON.stringify(IdTokenHolder)}
                        -
                        ${JSON.stringify(credential)}`
                      );
                    }
                  }
                }
                if (
                  preResponse &&
                  preResponse.scope &&
                  !preResponse.scope.includes(
                    "https://www.googleapis.com/auth/drive.file"
                  )
                ) {
                  // signOutUser();
                }
              }
              waitForElement();
              console.log("userdatabody", userDataBody);
            });
        });
    } else {
      var user = response.profileObj;
      handleProfileChange(user);
      handleUserIDChange(user.googleId);
      handleEmailChange(user.email);

      console.log("access Token:", tokenHolder.access_token);

      var credential = provider.credential(tokenHolder.id_token);
      // const oAuth2Client = new google.auth.OAuth2(
      //   client_id,
      //   client_secret,
      //   redirect_uris[0]
      // );
      // oAuth2Client.setCredentials(JSON.parse(token));

      // oAuth2Client.getToken(code, (err, token) => {
      //   if (err) {
      //     console.error("Error retrieving access token", err);
      //   }

      //   oAuth2Client.setCredentials(token);
      //   // Store the token to disk for later program executions
      //   fs.writeFile(TOKEN_PATH, JSON.stringify(token), (err) => {
      //     if (err) {
      //       console.error(err);
      //     }
      //     console.log("Token stored to", TOKEN_PATH);
      //   });
      // });

      firebase
        .auth()
        .signInWithCredential(credential)
        .then((credential) => {
          console.log("signInWith credential", credential, credential.user);
          if (credential.user) {
            handleRefreshTokenChange(credential.user.refreshToken);
          }

          userDataBody = {
            ...userDataBody,
            ...user,
            ...{
              email: user.email,
              scope: tokenHolder.scope,
              token_type: tokenHolder.token_type,
              expiry_date: tokenHolder.expires_at,
              user: user,
              access_token: tokenHolder.access_token,
            },
          };
          if (credential.user) {
          }

          handleAccessTokenChange({
            access_token: tokenHolder.access_token,
            scope: tokenHolder.scope,
            token_type: tokenHolder.token_type,
            expiry_date: tokenHolder.expires_at,
          });
          var headers = {
            "Content-Type": "text/plain",
          };
          var itt = 0;

          function waitForElement() {
            var userDataBody2 = {};
            if (IdTokenHolder || IdToken) {
              console.log("saveuserdatabody", userDataBody2);

              if (IdTokenHolder) {
                userDataBody2.token = IdTokenHolder;
              } else {
                userDataBody2.token = IdToken;
              }

              console.log("saveuserdatabody", userDataBody2);
              fetch(apiServer + "saveUserData", {
                method: "POST",
                body: JSON.stringify(userDataBody2),
                headers: headers,
              })
                .then((res) => {
                  gotHistory = false;

                  console.log("SaveUserData", res, res.body);
                })

                .catch((err) => {
                  console.log(err);
                  alert(
                    `schedule errored response:",
                  ${JSON.stringify(err)},
                  body:,
                  ${JSON.stringify(userDataBody2)}`
                  );
                });
              if (
                userDataBody2 &&
                userDataBody2.scope &&
                !userDataBody2.scope.includes(
                  "https://www.googleapis.com/auth/drive.file"
                ) &&
                false
              ) {
                signOutUser();
              }
            } else {
              itt++;
              console.log("no IdToken", IdTokenHolder);
              if (itt < 15) {
                setTimeout(waitForElement, 500);
              }
            }
          }
          waitForElement();
        });
    }
  };
  const onLogoutSuccess = () => {
    handleLoggedInChange(false);
    handleProfileChange(undefined);
    handleUserIDChange(undefined);
    handleEmailChange(undefined);
    firebase.auth().signOut();
    localStorage.setItem("sessionId", false);
  };

  function Copyright() {
    return (
      <Typography variant="body2" color="textSecondary" align="center">
        {"Copyright © "}
        <Link color="inherit" href="https://material-ui.com/">
          Your Website
        </Link>{" "}
        {new Date().getFullYear()}
        {"."}
      </Typography>
    );
  }

  const drawerWidth = 240;

  const useStyles = makeStyles((theme) => ({
    icon: {
      marginRight: theme.spacing(2),
    },
    heroContent: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(8, 0, 6),
    },
    heroButtons: {
      marginTop: theme.spacing(4),
    },
    cardGrid: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(8),
    },
    card: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
    },
    cardMedia: {
      //paddingTop: "56.25%", // 16:9
    },
    cardContent: {
      flexGrow: 1,
    },
    footer: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(6),
    },
    root: {
      display: "flex",
      backgroundColor: "#f9f9fa",
    },
    toolbar: {
      paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: "0 8px",
      ...theme.mixins.toolbar,
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      color: textColor,
      backgroundColor: background,
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: 36,
      color: textColor,
      backgroundColor: background,
    },
    menuButtonHidden: {
      display: "none",
    },
    title: {
      flexGrow: 1,
    },
    drawerPaper: {
      position: "relative",
      whiteSpace: "nowrap",
      width: drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerPaper2: {
      position: "relative",
      whiteSpace: "nowrap",
      width: "100vw",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },

    drawerPaperClose: {
      overflowX: "hidden",
      minWidth: 60,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: 60,
      [theme.breakpoints.up("sm")]: {
        width: 60,
      },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      overflow: "auto",
    },
    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
    paper: {
      padding: theme.spacing(2),
      display: "flex",
      overflow: "auto",
      flexDirection: "column",
    },
    fixedHeight: {
      height: 240,
    },
    button: {
      "text-transform": "none",
    },
  }));

  const classes = useStyles();
  const handleDrawerOpen = () => {
    localStorage.setItem("drawerOpen", true);
    setOpen(true);
  };
  const handleDrawerClose = () => {
    localStorage.setItem("drawerOpen", false);
    setOpen(false);
  };
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  var handleChangeComplete = (color) => {
    setBackground(color.hex);
  };
  var handleTextChange = (color) => {
    setText(color.hex);
  };

  async function uploadData() {
    // if(userID) {
    //   uploadFile("6aSmTovzOMU1xHpNrRVw227fhgI2", "b.webm", file)
    // }
  }

  async function uploadFile(uid, filename, file) {
    // Uploads a local file to the bucket

    var storageRef = storage.ref();
    console.log("storageRef", storageRef);

    var mountainsRef = storageRef.child(filename);
    var file = file;

    //	var g = storage.bucket("zoocorder.appspot.com")
    //	console.log('storageis',g);
    //	console.log('fs',fs);
    //	console.log('fsstat',fs.stat());
    //  await storage.bucket("zoocorder.appspot.com").up = load(filename, {
    //    // Support for HTTP requests made with `Accept-Encoding: gzip`
    //    gzip: true,
    //    // By setting the option `destination`, you can change the name of the
    //    // object you are uploading to a bucket.
    //    metadata: {
    //      // Enable long-lived HTTP caching headers
    //      // Use only if the contents of the file will never change
    //      // (If the contents will change, use cacheControl: 'no-cache')
    //      cacheControl: 'public, max-age=31536000',
    //    },
    //  });

    console.log(`${filename} uploaded to .`);
  }

  return (
    <div className={classes.root} style={{ height: "100vh" }}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="smallDrawer">
          {open && (
            <Drawer
              variant="permanent"
              classes={{
                paper: clsx(
                  classes.drawerPaper2,
                  !open && classes.drawerPaperClose
                ),
              }}
              open={open}
            >
              {" "}
              {/*
    <a
      onClick={() => {
        window.location.href = "/";
      }}
    >
      <div className={classes.toolbarIcon}>
        <img src={ZoomcorderLogo} className="logo" />
      </div>
    </a>*/}
              <Divider />
              <ListItem
                button
                onClick={handleDrawerClose}
                className={clsx(!open && classes.menuButtonHidden)}
              >
                <ListItemIcon>
                  <IconButton>
                    <ChevronLeftIcon />
                  </IconButton>{" "}
                </ListItemIcon>
                <ListItemText primary="Close" />
              </ListItem>
              <ListItem
                button
                onClick={handleDrawerOpen}
                className={clsx(open && classes.menuButtonHidden)}
              >
                <ListItemIcon>
                  <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleDrawerOpen}
                  >
                    {" "}
                    <MenuIcon />
                  </IconButton>
                </ListItemIcon>
                <ListItemText primary="Close" />
              </ListItem>
              <List onClick={handleDrawerClose}>{mainListItems}</List>
              <Divider />
              <List onClick={handleDrawerClose}>{secondaryListItems}</List>
            </Drawer>
          )}
        </div>

        <div className="fullDrawer">
          <Drawer
            variant="permanent"
            classes={{
              paper: clsx(
                classes.drawerPaper,
                !open && classes.drawerPaperClose
              ),
            }}
            open={open}
          >
            {" "}
            {/*
        <a
          onClick={() => {
            window.location.href = "/";
          }}
        >
          <div className={classes.toolbarIcon}>
            <img src={ZoomcorderLogo} className="logo" />
          </div>
        </a>*/}
            <Divider />
            <ListItem
              button
              onClick={handleDrawerClose}
              className={clsx(!open && classes.menuButtonHidden)}
            >
              <ListItemIcon>
                <IconButton>
                  <ChevronLeftIcon />
                </IconButton>{" "}
              </ListItemIcon>
              <ListItemText primary="Close" />
            </ListItem>
            <ListItem
              button
              onClick={handleDrawerOpen}
              className={clsx(open && classes.menuButtonHidden)}
            >
              <ListItemIcon>
                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawerOpen}
                >
                  {" "}
                  <MenuIcon />
                </IconButton>
              </ListItemIcon>
              <ListItemText primary="Close" />
            </ListItem>
            <List>{mainListItems}</List>
            <Divider />
            <List>{secondaryListItems}</List>
          </Drawer>
        </div>
        <main className={classes.content} tabIndex="-1">
          <div position="absolute" className="toolBar">
            <div className="smallDrawer">
              <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
              >
                <MenuIcon />
              </IconButton>
            </div>
            <div edge="start" aria-label="open drawer">
              <a
                onClick={() => {
                  window.location.href = "/";
                }}
              >
                <img src={ZoomcorderLogo} className="logo"></img>
              </a>
            </div>
            <Typography
              component="h1"
              variant="h4"
              noWrap
              className={classes.title}
              style={{ color: "#FFF" }}
            ></Typography>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              className={classes.title}
            ></Typography>
            <IconButton color="inherit">
              <div
                id="googleLoginButton"
                onClick={() => {
                  ReactGA.event({
                    id: Math.floor(Math.random() * 899999999 + 100000000),
                    category: "Login Initiated",
                    action: "Login Initiated",
                  });
                }}
              >
                {!profile && (
                  <GoogleLogin
                    clientId={clientID}
                    scope="https://www.googleapis.com/auth/drive.file https://www.googleapis.com/auth/userinfo.profile openid https://www.googleapis.com/auth/userinfo.email"
                    buttonText="Login"
                    prompt="consent"
                    cookiePolicy={"single_host_origin"}
                    isSignedIn={loggedIn}
                    accessType="offline"
                    responseType="code"
                    flow= 'auth-code'
                    onSuccess={successresponseGoogle}
                    onFailure={responseGoogle}
                  />
                )}
              </div>
              {profile && (
                <img
                  src={profile && profile.imageUrl}
                  aria-describedby={id}
                  variant="contained"
                  color="primary"
                  onClick={handleClick}
                  style={{ height: "40px" }}
                />
              )}
              <Popover
                id={id}
                open={openProfile}
                anchorEl={anchorEl}
                onClose={handleClose}
                style={{ "z-index": 0 }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                <img src={profile && profile.imageUrl} />
                <Typography className={classes.typography}>
                  {profile && profile.givenName} {profile && profile.familyName}{" "}
                  <br />
                  {profile && profile.email}
                </Typography>
                <Button
                  style={{ margin: "5px" }}
                  variant="contained"
                  color="white"
                  onClick={logoutButton}
                >
                  Sign Out
                </Button>
              </Popover>
            </IconButton>
          </div>
          {logoutPopup && (
            <div>
              {" "}
              <div id="confirmPop"></div>
              <div className="confirmSpacer">
                <div className="confirmHolder">
                  <div className="confirmHolder2">
                    <div id="submitConfirmHolder">
                      <Paper id="submitConfirm">
                        <Close className="closePopup" onClick={logoutButton} />

                        <div className="submitConfirm">
                          <div>
                            <h2 className="noMarginHeader">
                              Warning: Signing out will prevent recordings from
                              being uploaded to google drive. Are you sure you
                              want to sign out?
                            </h2>{" "}
                          </div>
                          <div className="width100">
                            <div className="alignRight">
                              <Button
                                style={{ margin: "5px" }}
                                variant="contained"
                                color="primary"
                                onClick={signOutUser}
                              >
                                Sign Out
                              </Button>{" "}
                              <Button
                                style={{ margin: "5px" }}
                                variant="contained"
                                color="secondary"
                                onClick={logoutButton}
                              >
                                Cancel
                              </Button>
                            </div>
                          </div>
                        </div>
                      </Paper>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <Paper
            style={{
              margin: "16px -2px",
              "min-height": "100vh",
              "max-width": "calc(100%)",
              "margin-left": "0px",
              border: "#CCC solid 0.1px",
            }}
            className="homePaper"
          >
            <div>
              <Switch loc={location}>
                <Route exact path="/">
                  <Dash
                    hasEmail={user.email && true}
                    clickedSchedule={clickedSchedule}
                    clickedScheduleEvent={clickedScheduleEvent}
                  />
                </Route>
                <Route exact path="/datatool">
                  <DataTool user={user} />
                </Route>

                <Route path="/extension/privacy.html">
                  <ExtPrivacy />
                </Route>
                <Route path="/recordings">
                  <Recordings
                    IdToken={IdToken}
                    schedule={schedule}
                    history={history}
                    handleHistoryChange={handleHistoryChange}
                    finalHistory={finalHistory}
                    handleFinalHistoryChange={handleFinalHistoryChange}
                    accessToken={accessToken}
                    reactGA={ReactGA}
                    deleteQueue={deleteQueue}
                    setDeleteQueue={setDeleteQueue}
                  />
                </Route>
                <Route path="/schedule/">
                  <Schedule
                    uID={uID}
                    IdToken={IdToken}
                    token={token}
                    accessToken={accessToken}
                    userID={userID}
                    email={email}
                    schedule={schedule}
                    user={user}
                    history={history}
                    firebase={firebase}
                    refreshToken={refreshToken}
                    reactGA={ReactGA}
                  />
                </Route>

                <Route path="/history">
                  <Hist
                    token={token}
                    userID={userID}
                    email={email}
                    user={user}
                    history={history}
                    handleHistoryChange={handleHistoryChange}
                    finalHistory={finalHistory}
                    handleFinalHistoryChange={handleFinalHistoryChange}
                    firebase={firebase}
                    accessToken={accessToken}
                    schedule={schedule}
                  />
                </Route>
                <Route path="/settings">
                  <Settings
                    token={token}
                    IdToken={IdToken}
                    userID={userID}
                    email={email}
                    user={user}
                    history={history}
                    handleHistoryChange={handleHistoryChange}
                    finalHistory={finalHistory}
                    handleFinalHistoryChange={handleFinalHistoryChange}
                    firebase={firebase}
                    accessToken={accessToken}
                    schedule={schedule}
                  />
                </Route>

                <Route path="/support" component={Support} />
                <Route path="/TOS" component={TOS} />
                <Route path="/Privacy" component={privacy} />

                <Route path="/DMCA" component={DMCA} />
                <Route path="/record">
                  <Pricing2
                    IdToken={IdToken}
                    token={token}
                    userID={userID}
                    user={user}
                    firebase={firebase}
                    reactGA={ReactGA}
                    profile={profile}
                  />
                </Route>

                <Route path="/pricing">
                  <Pricing
                    IdToken={IdToken}
                    token={token}
                    userID={userID}
                    user={user}
                    firebase={firebase}
                    reactGA={ReactGA}
                  />
                </Route>
                <Route path="/SubscriptionSuccess">
                  <Success
                    IdToken={IdToken}
                    token={token}
                    userID={userID}
                    user={user}
                    firebase={firebase}
                    reactGA={ReactGA}
                  />
                </Route>
                <Route path="/">
                  <Dash
                    hasEmail={user.email && true}
                    clickedSchedule={clickedSchedule}
                    clickedScheduleEvent={clickedScheduleEvent}
                  />
                </Route>
              </Switch>
            </div>
          </Paper>
          <footer
            style={{
              paddingTop: "20px",
              "font-size": "11px",
            }}
          >
            <div align="center">
              <a href="/TOS">Terms of Use</a>&nbsp;&nbsp;&nbsp;
              <a href="/Privacy">Privacy Policy</a> &nbsp;&nbsp;&nbsp;
              <a href="/DMCA">DMCA Notice</a>&nbsp;&nbsp;&nbsp;
              Zoomcorder&nbsp;is&nbsp;not&nbsp;affiliated&nbsp;with&nbsp;Zoom&nbsp;Video&nbsp;Communications,&nbsp;Inc
            </div>
            <div
              style={{ top: "-100px", position: "absolute", height: "5px" }}
              id="logoutbutton"
            >
              <GoogleLogout
                clientId={clientID}
                buttonText="Logout"
                onLogoutSuccess={onLogoutSuccess}
              ></GoogleLogout>
            </div>
          </footer>
        </main>
      </ThemeProvider>
    </div>
  );
}
