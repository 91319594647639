import React from "react";
// import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
}));

export default function Dash() {
  const classes = useStyles();

  return (
    <React.Fragment>
      <div className={classes.heroContent}></div>
      <div className={classes.appBarSpacer} />
      <Container
        style={{ maxWidth: "800px" }}
        className={classes.container}
      ></Container>
    </React.Fragment>
  );
}
