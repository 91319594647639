import React, { useEffect, useState, lazy, Suspense } from "react";
import ReactDOM from "react-dom";
import App from "./Dashboard.js";
import "./index.css";
import { BrowserRouter, Route, Switch } from "react-router-dom/";
import Fallback from "./Fallback.js";
import ReactGA from "react-ga4";
import ttiPolyfill from "tti-polyfill";
import DOMPurify from "dompurify";

ReactGA.initialize("G-JSDXDQGYC0", {
  debug: false,
});

ttiPolyfill.getFirstConsistentlyInteractive().then((tti) => {
  ReactGA.ga("send", "timing", {
    category: "Load Performace",
    variable: "Time to Interactive",
    value: tti,
  });
});

const callback = (list) => {
  list.getEntries().forEach((entry) => {
    // console.log(entry);
    ReactGA.ga("send", "timing", {
      category: "First Meaningful Paint",
      variable: entry.name,
      value: entry.responseEnd,
    });
  });
};

var observer = new PerformanceObserver(callback);
observer.observe({
  entryTypes: [
    "navigation",
    "paint",
    // "resource",
    // "mark",
    // "measure",
    // "frame",
    // "longtask",
  ],
});

const load = (Component: any) => (props: any) =>
  (
    <Suspense fallback={<Fallback />}>
      <Component {...props} />
    </Suspense>
  );
const Thanks = load(lazy(() => import("./thanks.js")));
const UninstallPage = load(lazy(() => import("./uninstall/index.js")));

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path="/thanks" component={Thanks} />
      <Route path="/thanks1" component={Thanks} />

      <Route path="/thanks2" component={Thanks} />

      <Route path="/thanks3" component={Thanks} />

      <Route path="/thanks4" component={Thanks} />

      <Route path="/thanks5" component={Thanks} />
      <Route path="/uninstall" component={UninstallPage} />

      <Route path="/">
        <App reactGA={ReactGA} />
      </Route>
    </Switch>
  </BrowserRouter>,

  document.getElementById("root")
);
