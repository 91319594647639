import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import PeopleIcon from "@mui/icons-material/People";
import BarChartIcon from "@mui/icons-material/BarChart";
import VideoLibrary from "@mui/icons-material/VideoLibrary";
import AssignmentIcon from "@mui/icons-material/Assignment";
import CalendarIcon from "@mui/icons-material/CalendarToday";
import SettingsIcon from "@mui/icons-material/Settings";
import ContactSupport from "@mui/icons-material/ContactSupport";
import AttachMoney from "@mui/icons-material/AttachMoney";
import HistoryIcon from "@mui/icons-material/History";
import { Link } from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip";

export const mainListItems = (
  <div>
    <Link to="/schedule">
      <ListItem button style={{ color: "black" }}>
        <Tooltip title="Schedule" placement="right">
          <ListItemIcon>
            <CalendarIcon />
          </ListItemIcon>
        </Tooltip>
        <ListItemText primary="Schedule" />
      </ListItem>
    </Link>
    <Link to="/recordings">
      <ListItem button style={{ color: "black" }}>
        <Tooltip title="Recordings" placement="right">
          <ListItemIcon>
            <VideoLibrary />
          </ListItemIcon>
        </Tooltip>
        <ListItemText primary="Recordings" />
      </ListItem>
    </Link>
    <Link to="/pricing">
      <ListItem button style={{ color: "black" }}>
        <Tooltip title="Pricing" placement="right">
          <ListItemIcon>
            <AttachMoney />
          </ListItemIcon>
        </Tooltip>
        <ListItemText primary="Pricing" />
      </ListItem>
    </Link>
    <Link to="/history">
      <ListItem button style={{ color: "black" }}>
        <Tooltip title="History" placement="right">
          <ListItemIcon>
            <HistoryIcon />
          </ListItemIcon>
        </Tooltip>
        <ListItemText primary="History" />
      </ListItem>
    </Link>
    <Link to="/settings">
      <ListItem button style={{ color: "black" }}>
        <Tooltip title="Settings" placement="right">
          <ListItemIcon>
            <SettingsIcon />
          </ListItemIcon>
        </Tooltip>
        <ListItemText primary="Settings" />
      </ListItem>
    </Link>
  </div>
);

export const secondaryListItems = (
  <div>
    <a
      onClick={() => {
        window.location.href = "support";
      }}
    >
      <ListItem button style={{ color: "black" }}>
        <Tooltip title="FAQ" placement="right">
          <ListItemIcon>
            <ContactSupport />
          </ListItemIcon>
        </Tooltip>
        <ListItemText primary="FAQ" />
      </ListItem>
    </a>
  </div>
);
